import React, {useEffect} from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import Icon from "../../utilities/icons"
import { SideInfoBox } from "../../components/styles/SideInfoBox"
import SEO from "../../components/seo"



const EyelashPage = () => {

  useEffect(() => {
    // Update the document title using the browser API
    window.location.replace("/treatments/lash-lift");
  });

  return (
    <Layout>
      <SEO
        title="Eyelash Extensions Newark | Marvelash | Beauty Gems"
        description="Mobile beauty therapist, offering Marvelash Eyelash Extensions in Newark, Claypole, Fernwood, Winthorpe, Coddington, Collingham, Elston, Muskham and other surrounding areas."
      />
      {/*<div class="standard-page-container">
        <div className="inner-container">
          <h1>Marvelash Eyelash Extensions</h1>
          <div className="two-column-layout-wrapper">
            <div className="two-column-layout-item_wide ">
              <p>
                Marvelash eyelash extensions are a fantastic semi-permanent
                method of creating longer, fuller glamorous lashes that will
                last for up to 2 months.
              </p>
              <div
                style={{ marginTop: "40px", marginBottom: "40px" }}
                className="global-btn-wrapper "
              >
                <Link
                  className="global-btn global-btn_blue"
                  to="/contact"
                  state={{ treatment: "Eyelash Extensions" }}
                >
                  Book Appointment
                </Link>
              </div>
            </div>
            <div className="two-column-layout-item_narrow">
              <SideInfoBox className="global-box-shadow">
                <h3 className="sidebox-title">Vouchers Available</h3>
                <p>
                  You can purchase vouchers from me, which are great for that
                  special occasion; birthdays, Christmas and anniversaries. A
                  simple but thoughtful and effective gift for that special
                  person in your life.
                </p>
              </SideInfoBox>
            </div>
          </div>
        </div>
  </div>*/}
    </Layout>
  )
}

export default EyelashPage
